import {onFind} from "@elements/init-modules-in-scope";
import './scripts/common';

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

// Todo: Add page specific JS here e.g:
import * as scrollSlider from '@elements/scroll-slider';
scrollSlider.init();

import * as priceTable from './scripts/price-table.js';
priceTable.init();

import * as calcRoute from './scripts/calculate-route.js';
calcRoute.init();

import Collapse from 'bootstrap/js/dist/collapse';
onFind('[data-bs-toggle="collapse"]',function (element) {
    new Collapse(element);
});

import * as lazyIframe from '@elements/lazy-iframe';
lazyIframe.init();

import * as formValidation from '@elements/form-validation';
formValidation.init();

import * as passwordStrength from './scripts/password-strength.js';
passwordStrength.init();

import * as passwordRepeat from './scripts/password-repeat.js';
passwordRepeat.init();

import * as recaptcha from './scripts/recaptcha';
recaptcha.init();

import * as reloadForm from "@elements/reload-form";
reloadForm.init();

import * as cookieConsent from './scripts/cookieconsent-overlay';
cookieConsent.init();