import {onFind} from "@elements/init-modules-in-scope";
import {on, findIn, closest} from "@elements/dom-utils";

export function init() {
    onFind('.js-cookieconsent-overlay', (overlay) => {
        let consentAcceptButton = findIn('.js-consent__accept-btn', overlay);
        console.log("cookieconsent-overlay.js: consentAcceptButton", consentAcceptButton);
        consentAcceptButton.addEventListener('click', () => {
            Cookiebot.submitCustomConsent(true, true, true);
            setTimeout(function () {
                window.location.reload(true);
            }, 200);
        })
    })
}