"use strict";

import {findAllIn, on, closest, findIn, setAttribute, removeAttribute} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";
import axios from "axios";

export function init() {
    onFind('.js-price-table__load', function (checkbox) {
        on('change', function () {
            let container = closest(('.js-price-table-container'), checkbox);
            console.log(findAllIn('.js-price-table', container))
            findAllIn('.js-price-table', container).map((priceTable) => {
                let url = priceTable.getAttribute('data-table-url'),
                    loading = findIn('.js-price-table__loading', priceTable),
                    result = findIn('.js-price-table__result', priceTable);

                removeAttribute('hidden', loading);

                axios({
                    method: 'get',
                    url: url,
                    params: {
                        online: checkbox.checked
                    }})
                    .then(function (response) {
                        let data = response.data;
                        if (data.success) {
                            console.log('update table')
                            result.innerHTML = data.html;
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
                    .then(function () {
                        setAttribute('hidden', true, loading);

                        // always executed
                    });
            });
        }, checkbox);
    });
}