
import loadScript from "@elements/load-script";
import {find, findIn, setAttribute, removeAttribute, on, trigger, hasClass} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";
import * as formValidation from '@elements/form-validation';

//recaptcha v3

export function init() {

    onFind('.js-recaptcha', function(baseElement) {
        const API_KEY = _config.recaptchaPublicKey;

        loadRecaptchaAPI(API_KEY).then(function () {
            let isValidated = false;
            let submitButton = find('.js-recaptcha__submit', baseElement);

            grecaptcha.ready(function () {
                let isValidated = false;

                on('submit', function(evt) {
                    let hasFormValidation = hasClass('js-form-validation', baseElement) || false;
                    evt.preventDefault();
                    let recaptchaAction =  baseElement.getAttribute('data-recaptcha-action');

                    if (!isValidated) {
                        grecaptcha.execute(API_KEY, {action: recaptchaAction}).then(function (token) {
                            let recaptchaResponse = findIn('.js-recaptcha__response', baseElement);

                            if (!recaptchaResponse) {
                                recaptchaResponse = document.createElement('input');
                                recaptchaResponse.type = 'hidden';
                                recaptchaResponse.name = 'g-recaptcha-response';
                                baseElement.appendChild(recaptchaResponse);
                            }
                            recaptchaResponse.value = token;
                            isValidated = true;
                            baseElement.submit();
                        })
                    }
                }, baseElement);


                let submitButton = findIn('.js-recaptcha__submit', baseElement);
                if(submitButton) {
                    on('click', function(evt) {
                        evt.preventDefault();
                        let hasFormValidation = hasClass('js-form-validation', baseElement) || false;

                        if(hasFormValidation) {
                            formValidation.getApi(baseElement).then((formApi) => {
                                let formInstance = formApi.getFormInstance();
                                formInstance.validate().then((state) => {
                                    if (state === 'Valid') {
                                        let hasFormValidation = hasClass('js-form-validation', baseElement) || false;
                                        let recaptchaAction =  baseElement.getAttribute('data-recaptcha-action');

                                        if (!isValidated) {
                                            trigger('submit', baseElement);
                                        }
                                    }
                                });
                            });
                        }
                    }, submitButton);
                }
            });
        });
    });
}

export function loadRecaptchaAPI(API_KEY) {
    return loadScript(`https://www.google.com/recaptcha/api.js?render=${API_KEY}`);
}
